<template>
    <input ref="pictureUpload"
        accept="image/*"
        style="display: none"
        type="file"
        @change="onFileChange"/>
    <div class="relative inline-block">
        <span v-if="data.photo">
            <img :src="data.photo" alt="Profile image"
                 class="inline-block object-cover rounded-full" style="width: 126px; height: 126px;"/>
        </span>
        <span v-else>
            <span class="inline-block object-cover rounded-full bg-primary"
                style="width: 126px; height: 126px;">
                <span class="flex h-full justify-center items-center text-white" :class="{ 'text-4xl': initials.length <= 2, 'text-2xl': initials.length > 2 }">
                    <template v-if="initials.length">{{ initials }}</template>
                    <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-user"
                            width="60" height="60" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor"
                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="7" r="4" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                    </template>
                </span>
            </span>
        </span>

        <!-- mode when there is no photo value -->
        <span v-if="!data.photo" class="absolute bottom-0 right-0 inline-block bg-white border-2 border-white rounded-full cursor-pointer"
              style="height: 35px; width: 35px;" @click="selectFile">
            <span class="flex justify-center items-center mt-1">
                <svg fill="none" height="19" viewBox="0 0 19 19" width="19"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M17.4659 15.1451C17.4659 15.5462 17.3065 15.9308 17.0229 16.2145C16.7393 16.4981 16.3546 16.6574 15.9535 16.6574H2.34242C1.94132 16.6574 1.55665 16.4981 1.27303 16.2145C0.989414 15.9308 0.830078 15.5462 0.830078 15.1451V6.82716C0.830078 6.42606 0.989414 6.04139 1.27303 5.75777C1.55665 5.47415 1.94132 5.31481 2.34242 5.31481H5.36711L6.87946 3.0463H11.4165L12.9288 5.31481H15.9535C16.3546 5.31481 16.7393 5.47415 17.0229 5.75777C17.3065 6.04139 17.4659 6.42606 17.4659 6.82716V15.1451Z"
                            stroke="#195680" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                        <path
                            d="M9.14798 13.6327C10.8185 13.6327 12.1727 12.2785 12.1727 10.608C12.1727 8.93753 10.8185 7.58333 9.14798 7.58333C7.47749 7.58333 6.12329 8.93753 6.12329 10.608C6.12329 12.2785 7.47749 13.6327 9.14798 13.6327Z"
                            stroke="#195680" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                        <rect fill="white" height="18.1481" transform="translate(0.0739746 0.777771)"
                              width="18.1481"/>
                        </clipPath>
                    </defs>
                </svg>
            </span>
        </span>

        <!-- Edit mode when there is a photo value -->
        <span v-if="data.photo"
            class="absolute bottom-0 right-0 inline-block bg-white border-2 border-white rounded-full cursor-pointer"
            style="height: 35px; width: 35px;" @click="removePhoto">
            <span class="flex justify-center items-center mt-1">
                <svg fill="#195680" height="19px" viewBox="0 0 24 24" width="19px"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"/>
                </svg>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: "image-uploader",
    emits: ['imageUpload'],
    props: {
        data: {
            type: Object,
            required: true
        },
        initials: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            base64ImageString: ''
        }
    },
    methods: {
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },

        async onFileChange(event) {
            this.base64ImageString = await this.toBase64(event.target.files[0])
            this.$emit('imageUpload', this.base64ImageString)
        },

        removePhoto() {
            this.$emit('imageUpload', null)
        },

        selectFile() {
            this.$refs.pictureUpload.click()
        }
    }
}
</script>

<style scoped>

</style>